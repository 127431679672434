import { toast } from "react-toastify";
import apiClient from "services/axios/axios";
import { ClientFile } from "utils/interfaces";

export default class FilesController {
    static defaultClientFiles: ClientFile[] = []

    static async uploadFiles(client_id: number | string, data: FormData) {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.post(`/clients/${client_id}/files/upload`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }), {
            pending: 'Fazendo upload ...',
            error: 'Erro ao realizar upload',
        })
            .catch((error) => {
                console.log(error);
            });
    };

    static async getFiles(id: string | number): Promise<ClientFile[]> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/clients/${id}/files`), {
            pending: 'Atualizando Lista de Arquivos',
            success: 'Lista de Arquivos Atualizada',
            error: 'Erro ao Obter Lista de Arquivos',
        })
            .then((response) => {
                if (response.data) {
                    return response.data
                }
                else {
                    return this.defaultClientFiles
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultClientFiles
            });
    };

    static async deleteFiles(id: string | number, file_id: string | number) {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.delete(`/clients/${id}/files/${file_id}`), {
            pending: 'Atualizando Lista de Arquivos',
            success: 'Lista de Arquivos Atualizada',
            error: 'Erro ao Obter Lista de Arquivos',
        })
            .then((response) => {
                if (response.data) {
                    return response.data
                }
                else {
                    return this.defaultClientFiles
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultClientFiles
            });
    };

    static async getDownloadLink(id: string | number, file_id: string | number, file_name?: string) {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/clients/${id}/files/${file_id}/download`, {
            responseType: "blob",
        }), {
            pending: 'Baixando arquivo',
            error: 'Erro ao Obter Arquivo',
        }).then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            //Para manter a compatibilidade uso um link virtual para efetivar o download
            const link = document.createElement("a");
            link.href = url;

            const contentDisposition = response.headers["content-disposition"];
            const fileName = file_name ? file_name : contentDisposition
                ? contentDisposition.split("filename=")[1].replace(/['"]/g, "")
                : "downloaded-file";

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
            .catch((error) => {
                console.log(error);
            });
    };
}