import Menu from "views/Menu/Menu";
import React, { useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "contexts/AuthProvider";
import Navbar from "components/Layout/Navbar";
import ThemeManager from "utils/ThemeManager";

type Props = {
  children?: React.ReactNode;
};

const SecuredLayout = ({ children }: Props) => {
  // const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    ThemeManager.setColors("violet");
    ThemeManager.initializeTheme();
  }, []);

  // if (isLoggedIn === false) {
  //   return <Navigate to="/login" replace />;
  // }
  return (
    <>
      <div className="flex h-screen antialiased text-base-content bg-base-100 dark:text-light">
        <Menu>
          <div className="flex-1 h-full overflow-x-hidden">
            <Navbar />
            <div className="h-[calc(100vh-50px)] overflow-y-auto">
              <Outlet />
            </div>
          </div>
        </Menu>
      </div>
    </>
  );
};

export default SecuredLayout;
