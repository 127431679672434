import { Client } from "./interfaces";

export interface ClientFormModel {
    id?: number;
    document: string;
    documentType: string;
    type: string;
    name: string;
    fantasyName: string;

    cityId: string;
    stateId: string;

    street: string;
    district: string;

    number: string;
    zip: string;

    complement: string;
    nearby: string;

    contact: {
        id?: number;
        contact: string;
        typeContact: string;
    }[]

    info: string;
    domainId: string;
}

export const transformClientToFormModel = (client: Client): ClientFormModel => {
    return {
        id: client.id,
        document: client.document,
        documentType: String(client.document_type),
        type: String(client.type),
        name: client.name,
        fantasyName: client.fantasy_name,
        cityId: String(client.address.city_id),
        stateId: String(client.address.city.state_id),
        street: client.address.street,
        district: client.address.district,
        number: client.address.number,
        zip: client.address.zip,
        complement: client.address.complement,
        nearby: client.address.nearby,
        contact: client.contacts.map((contact) => ({
            id: contact.id,
            contact: contact.contact,
            typeContact: String(contact.type),
        })),
        info: client.info,
        domainId: "",
    };
};