import React, { useEffect, useState } from "react"
import LabelInsideInput from "./Input/LabelInsideInput"
import Select from "./Input/Select"
import InlineLabelButton from "./Input/InlineLabelButton"
import TextInput from "./Input/TextInput"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ClientFormModel, transformClientToFormModel } from "utils/DTOs"
import { normalizeCepNumber, normalizeCnpjNumber } from "utils/formMasks"
import ClientController from "Controllers/ClientController"
import { Location } from "utils/interfaces"

interface InputData {
    idForm: string,
    selectedID?: number
}

const ClientForm: React.FC<InputData> = ({ idForm, selectedID }) => {
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<ClientFormModel>({})

    const closeDrawer = () => {
        const drawerCheckbox = document.getElementById(idForm) as HTMLInputElement;
        if (drawerCheckbox) {
            drawerCheckbox.checked = false;
        }
    };

    const onSubmit: SubmitHandler<ClientFormModel> = (data) => {
        if (data.id) {
            ClientController.put(data);
        } else {
            ClientController.store(data);
        }
        closeDrawer();
        reset();
    }

    const { fields, append, remove } = useFieldArray({
        control,
        name: "contact",
    });

    const [citiesList, setCitiesList] = useState<Location[]>([])
    const [statesList, setStatesList] = useState<Location[]>([])
    const stateId = watch("stateId")
    const document_ = watch("document")
    const cepValue = watch("zip")
    const id = watch("id")

    useEffect(() => {
        setValue("document", normalizeCnpjNumber(document_))
    }, [document_, setValue])

    useEffect(() => {
        setValue("zip", normalizeCepNumber(cepValue))
    }, [cepValue, setValue])

    useEffect(() => {
        if (stateId) {
            const updateCities = async () => setCitiesList(await ClientController.getCities(stateId));
            updateCities();
        }
    }, [stateId])

    useEffect(() => {
        const updateStates = async () => setStatesList(await ClientController.getStates());
        updateStates();
    }, [])

    useEffect(() => {
        const getUser = async () => {
            if (selectedID) {
                const client = await ClientController.getClientById(selectedID)
                client && reset(transformClientToFormModel(client));
            }
        }
        getUser()
    }, [selectedID, reset])

    return <div className="drawer drawer-end">
        <input id={idForm} type="checkbox" className="drawer-toggle" />
        <div className="drawer-side">
            <label htmlFor={idForm} aria-label="close sidebar" className="drawer-overlay"></label>
            <form onSubmit={handleSubmit(onSubmit)} className="menu bg-base-200 text-base-content min-h-full w-[800px] p-4">
                <div className='grid grid-cols-3 mb-3 gap-2 items-end  auto-rows-max'>
                    <input type="hidden" {...register("id")} />

                    <Select label="Selecione o tipo de cliente"
                        defaultOption="Tipo de Pessoa"
                        options={[{ value: 0, label: "Fisica" }, { value: 1, label: "Juridica" }]}
                        {...register("documentType", { required: true })}
                        error={errors.documentType}
                    />

                    <label className="input input-bordered flex items-center gap-2 col-span-2">
                        <input type="text" className="grow" placeholder="Procurar CNPJ" {...register("document", { required: true })} />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70">
                            <path
                                fillRule="evenodd"
                                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                clipRule="evenodd" />
                        </svg>
                    </label>
                </div>
                <div className='grid grid-cols-2 gap-2 items-end  auto-rows-max'>
                    <LabelInsideInput label="Nome" error={errors.name} {...register("name", { required: true })} />
                    <LabelInsideInput label="Fantasia" error={errors.fantasyName} {...register("fantasyName", { required: true })} />

                    <div className="text-sm col-span-2">
                        Informações de Endereço
                    </div>
                    <Select label="Selecione um estado" defaultOption="Escolha uma estado"
                        options={statesList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                        }))}
                        error={errors.stateId} {...register("stateId", { required: true })} />
                    <Select label="Selecione uma cidade" defaultOption="Escolha uma cidade" options={citiesList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                    }))}
                        error={errors.cityId} {...register("cityId", { required: true })} />

                    <TextInput label="Rua" error={errors.street} {...register("street", { required: true })} />
                    <TextInput label="Bairro" error={errors.district} {...register("district", { required: true })} />
                    <TextInput label="Numero" error={errors.number} {...register("number", { required: true })} />
                    <TextInput label="Cep" error={errors.zip} {...register("zip", { required: true })} />
                    <TextInput label="Proximo" error={errors.nearby} {...register("nearby", { required: true })} />
                    <TextInput label="Complemento" error={errors.complement} {...register("complement", { required: true })} />

                </div>
                <div className='grid grid-cols-3 gap-2 items-end my-3'>
                    <div className="text-sm col-span-3">
                        Informações de Contato
                    </div>
                    {fields.map((field, index) => (<>
                        <TextInput label="Contato"
                            key={field.id}
                            {...register(`contact.${index}.contact`, { required: true })}
                            error={errors.contact?.[index]?.contact}
                        />
                        <Select label="Tipo" defaultOption="Selecione um tipo" options={[{ value: 0, label: "Email" },
                        { value: 1, label: "Mobile" },
                        { value: 2, label: "Telefone" },
                        ]}
                            {...register(`contact.${index}.typeContact`, { required: true })}
                            error={errors.contact?.[index]?.typeContact}
                        />
                        <div className="flex flex-row w-full items-end">
                            <input type="checkbox" aria-label="Selecionar como Padrão" className="btn" />
                            <InlineLabelButton
                                onClick={() => remove(index)} label="Remover" />
                        </div>
                    </>
                    ))}

                </div>
                <button
                    type="button"
                    onClick={() => append({ typeContact: "", contact: "" })}
                    className="btn btn-block btn-sm">Adicionar outro Contato</button>

                <button className="btn my-3 btn-primary">{id ? "Salvar" : "Cadastrar"}</button>
            </form>
        </div>
    </div>
}

export default ClientForm