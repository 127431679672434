import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
};

const OpenLayout = ({ children }: Props) => {
  return (
    <>
      {children}
      <Outlet />
    </>
  );
};

export default OpenLayout;