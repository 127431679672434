import { useState } from "react";
import { Link } from "react-router-dom";

type MenuItem = {
    label: string;
    icon?: React.ReactNode;
    submenu?: MenuItem[];
    href?: string;
    disabled?: boolean;
};

const MenuGroup: React.FC<MenuItem> = ({ label, icon, submenu, disabled }) => {
    return (
        <div>
            <ul className="menu rounded-box px-0 text-base-content stroke-base-content fill-base-content">
                <li>
                    <details >
                        <summary>
                            {icon && icon}
                            {label}
                        </summary>
                        <ul>
                            {submenu && submenu.map((subItem, subIndex) => (
                                subItem.href && <li> <Link
                                    key={subIndex}
                                    to={subItem.href}
                                >
                                    {subItem.label}
                                </Link></li>
                            ))}
                        </ul>
                    </details>
                </li>
            </ul>
        </div >
    );
};
export default MenuGroup