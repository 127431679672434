import { toast } from "react-toastify";
import apiClient from "services/axios/axios";
import { ClientFormModel } from "utils/DTOs";
import { Client, ClientPlan, Location, PaginatedResponse } from "utils/interfaces";

export default class ClientController {
    static defaultPaginatedResponse: PaginatedResponse<Client> = {
        current_page: 1,
        data: [],
        from: 0,
        last_page: 1,
        per_page: 0,
        to: 0,
        total: 0,
    }

    static defaultClientPlan: ClientPlan = { plans: [], total_value: 0 }

    static async getClientPage(page: number): Promise<PaginatedResponse<Client>> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/clients/index?page=${page}`), {
            pending: 'Atualizando lista de Clientes...',
            error: 'Erro ao obter lista de Clientes',
        })
            .then((response) => {
                if (response.data) {
                    return response.data as PaginatedResponse<Client>
                }
                else {
                    return this.defaultPaginatedResponse
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultPaginatedResponse
            });
    };

    static async getClientById(id: number): Promise<Client | undefined> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/clients/${id}`), {
            pending: 'Obtendo Informações de cliente...',
            error: 'Erro ao obter Informações do Clientes',
        })
            .then((response) => {
                if (response.data) {
                    return response.data as Client
                }
                else {
                    return undefined
                }
            })
            .catch((error) => {
                console.log(error);
                return undefined
            });
    };

    static async store(client: ClientFormModel) {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.post(`/clients/store`, {
            ...client,
            "fantasy_name": client.fantasyName,
            "document_type": client.documentType,
            "address": {
                "street": client.street,
                "number": client.number,
                "complement": client.complement,
                "district": client.district,
                "nearby": client.nearby,
                "zip": client.zip,
                "city_id": client.cityId
            },
            "contacts": client.contact.map(({ contact, typeContact }) => ({
                contact,
                type: typeContact
            }))
        }), {
            pending: 'Cadastrando Cliente',
            success: 'Cadastro concluido',
            error: 'Erro ao realizar cadastro',
        })
            .then((response) => {
                if (response.data) {
                    return response.data as PaginatedResponse<Client>
                }
                else {
                    return this.defaultPaginatedResponse
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultPaginatedResponse
            });
    };

    static async put(client: ClientFormModel) {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.put(`/clients/${client.id}`, {
            ...client,
            "fantasy_name": client.fantasyName,
            "document_type": client.documentType,
            "address": {
                "street": client.street,
                "number": client.number,
                "complement": client.complement,
                "district": client.district,
                "nearby": client.nearby,
                "zip": client.zip,
                "city_id": client.cityId
            },
            "contacts": client.contact.map(({ contact, typeContact }) => ({
                contact,
                type: typeContact
            }))
        }), {
            pending: 'Cadastrando Cliente',
            success: 'Cadastro concluido',
            error: 'Erro ao realizar cadastro',
        })
            .then((response) => {
                if (response.data) {
                    return response.data as PaginatedResponse<Client>
                }
                else {
                    return this.defaultPaginatedResponse
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultPaginatedResponse
            });
    };

    static async getStates(): Promise<Location[]> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/states`), {
            pending: 'Obtendo Lista de Estados',
            success: 'Lista de Estados Atualizada',
            error: 'Erro ao Obter Lista de Estados',
        })
            .then((response) => {
                if (response.data) {
                    return response.data
                }
                else {
                    return []
                }
            })
            .catch((error) => {
                console.log(error);
                return []
            });
    };


    static async getCities(id: string): Promise<Location[]> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/cities/${id}`), {
            pending: 'Atualizando Lista de Cidades',
            success: 'Lista de Cidades Atualizada',
            error: 'Erro ao Obter Lista de Cidades',
        })
            .then((response) => {
                if (response.data) {
                    return response.data
                }
                else {
                    return []
                }
            })
            .catch((error) => {
                console.log(error);
                return []
            });
    };

    static async getPlans(id: string | number): Promise<ClientPlan> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/clients/plans/${id}`), {
            pending: 'Atualizando Lista de Planos',
            success: 'Lista de Planos Atualizada',
            error: 'Erro ao Obter Lista de Planos',
        })
            .then((response) => {
                if (response.data) {
                    return response.data
                }
                else {
                    return this.defaultClientPlan
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultClientPlan
            });
    };

    static async attachPlan(plan_id: string | number, client_id: string, signature_date: string) {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.post(`/clients/${client_id}/plan/${plan_id}`, {
            'signature_date': signature_date
        }), {
            pending: 'Cadastrando Cliente',
            success: 'Cadastro concluido',
            error: 'Erro ao realizar cadastro',
        })
            .then((response) => {
                if (response.data) {
                    return response.data as PaginatedResponse<Client>
                }
                else {
                    return this.defaultPaginatedResponse
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultPaginatedResponse
            });
    };
}