import React, { MouseEvent } from 'react';

interface ButtonProps {
  action: (event: MouseEvent<HTMLButtonElement>) => void;
  color: string;
  type: 'button' | 'submit' | 'reset';
  text?: string;
  cssClass?: string;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button
      onClick={props.action}
      className={`btn btn-${props.color}${props.cssClass === undefined? '' : props.cssClass}`}
      type={props.type}>
      {props.text ? props.text : props.children}
    </button>
  );
};

export default Button;
