import React, { ChangeEvent, useEffect, useState } from 'react';

type FormatType = 'currency' | 'zip' | 'phone' | 'string' | 'cpf_cnpj';

interface TextInputProps {
  className?: string;
  topLabelLeft?: string;
  topLabelRight?: string;
  name: string;
  id?: string;
  onChange: (value: string) => void;
  value: string;
  type: string;
  bottomLabelLeft?: string;
  bottomLabelRight?: string;
  format: FormatType;
};

const formatCurrency = (value: string) => {
  const numericValue = value.replace(/[^0-9]/g, '').slice(0, 15);
  const intValue = parseInt(numericValue, 10);

  if (isNaN(intValue)) {
    return '0,00';
  }

  const formattedValue = (intValue / 100).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedValue;
};

const format = (value: string, format: string) => {
  let formattedValue = '';

  if (format === 'currency') {
    formattedValue = formatCurrency(value);
  } else if (format === 'zip') {
    const numericValue = value.replace(/[^0-9]/g, '');
    formattedValue = numericValue.slice(0, 8).replace(/(\d{5})(\d{3})/, '$1-$2');
  } else if (format === 'phone') {
    const numericValue = value.replace(/[^0-9]/g, '');
    formattedValue = numericValue.slice(0, 11).replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else if (format === 'string') {
    formattedValue = value;
  } else if (format === 'cpf_cnpj') {
    const numericValue = value.replace(/[^0-9]/g, '');
    if (numericValue.length > 11) {
      formattedValue = numericValue.slice(0, 14).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      formattedValue = numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
  }

  return formattedValue;
};

const TextInput: React.FC<TextInputProps> = (props) => {
  const [inputValue, setInputValue] = useState(props.value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let rawValue = event.target.value
    if(props.format === 'currency') {
      rawValue = event.target.value.replace(/[^0-9]/g, '');
    }
    setInputValue(rawValue);
    props.onChange(format(rawValue, props.format));
  };

  useEffect( () => {
    setInputValue(props.value)
  }, [props]);

  return (
    <label className={`form-control w-full ${props.className}`}>
      {props.topLabelLeft || props.topLabelRight ? (
        <div className="label">
          {props.topLabelLeft && <span className="label-text">{props.topLabelLeft}</span>}
          {props.topLabelRight && <span className="label-text-alt">{props.topLabelRight}</span>}
        </div>
      ) : null}
      <input
        name={props.name}
        id={props.id}
        onChange={handleChange}
        value={format(inputValue, props.format)}
        type={props.type}
        className="input input-bordered w-full"
      />
      {props.bottomLabelLeft || props.bottomLabelRight ? (
        <div className="label">
          {props.bottomLabelLeft && <span className="label-text-alt">{props.bottomLabelLeft}</span>}
          {props.bottomLabelRight && <span className="label-text-alt">{props.bottomLabelRight}</span>}
        </div>
      ) : null}
    </label>
  );
};

export default TextInput;
