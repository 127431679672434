import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TableWithExpandableRows from "components/Table/PlanTable";
import { PaginatedResponse, Plan } from "utils/interfaces";
import PlanController from "Controllers/PlanController";
import PageController from "components/Table/PageController";

const PlanList: React.FC = () => {
  const [tablePlans, setTablePlans] = useState<PaginatedResponse<Plan>>(
    PlanController.defaultPaginatedResponse
  );

  const getPlansPerPage = async (id: number) => {
    setTablePlans(await PlanController.getPlanPage(id));
  };

  useEffect(() => {
    getPlansPerPage(1);
  }, []);

  return (
    <div className="card shadow-xl w-full">
      <div className="card-body">
        <h2 className="card-title mb-1">
          <div className="mr-auto">Planos</div>
          <Link to={"/clients/create"} className="btn btn-primary">
            Adicionar Plano
          </Link>
        </h2>
        <div className="overflow-x-auto">
          <TableWithExpandableRows rows={tablePlans.data} />
          <PageController {...tablePlans} changePage={getPlansPerPage} />
        </div>
      </div>
    </div>
  );
};

export default PlanList;
