import React from 'react';
import Login from './Login';
import Home from 'views/Home/Home';
import PlanList from 'views/Plans/PlanIndex';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ClientList from 'views/Clients/ClientList';
import OpenLayout from './views/Layouts/OpenLayout';
import SecuredLayout from './views/Layouts/SecuredLayout';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ClientInfo from 'views/Clients/ClientInfo';
import ClientPlans from 'views/Clients/ClientPlans';
import ThemeManager from 'utils/ThemeManager';
import ClientInvoices from 'views/Clients/ClientInvoices';
import ClientFiles from 'views/Clients/ClientFiles';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<SecuredLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/clients/files/:id" element={<ClientFiles />} />
            <Route path="/clients/index" element={<ClientList />} />
            <Route path="/clients/:id" element={<ClientInfo />} />
            <Route path="/clients/plans/:id" element={<ClientPlans />} />
            <Route path="/clients/invoices/:id" element={<ClientInvoices />} />
            <Route path="/plans/index" element={<PlanList />} />
          </Route>
          <Route element={<OpenLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={ThemeManager.getTheme()}
      />
    </>
  )
}

export default App;