import React, { useEffect, useState } from 'react';
import { ClientPlan, Plan } from 'utils/interfaces';
import ClienteTabs from 'components/Tabs/ClienteTabs';
import { useParams } from 'react-router-dom';
import ClientController from 'Controllers/ClientController';
import ClientPlanTable from 'components/Table/ClientePlanTable';

const ClientInvoices: React.FC = () => {
    const { id: ClientID } = useParams();
    const [tablePlans, setTablePlans] = useState<ClientPlan>(ClientController.defaultClientPlan);

    const updatePlans = async (id: string) => {
        const plans_list = await ClientController.getPlans(id)
        setTablePlans(plans_list);
    }

    useEffect(() => {
        if (ClientID) {
            updatePlans(ClientID);
        }
    }, []);

    return (
        <div className="card shadow-xl w-full">
            <div className="card-body">
                <ClienteTabs id={ClientID} />
                <div className="divider"></div>
                <ClientPlanTable rows={tablePlans.plans} />
            </div>
        </div>
    );
}

export default ClientInvoices