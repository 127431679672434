import React from "react";
import { ClientPlanInfo } from "utils/interfaces";
interface RowData {
  row: ClientPlanInfo;
}

const ClientPlanRows: React.FC<RowData> = ({ row }) => {
  function formatDate(apiDate: string) {
    const date = new Date(apiDate);
    return date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }
  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap">{row.name}</td>
        <td>{row.cancellation_date ? "Cancelado" : "Ativo"}</td>
        <td>{formatDate(row.signature_date)}</td>
        <td>{row.value}</td>
        <td>
          <div className="dropdown dropdown-left dropdown-hover">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost rounded-btn"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 3.5H15M0 11.5H15M0 7.5H15" stroke="white" />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content bg-base-100 rounded-box z-[1] mt-4 w-52 p-2 shadow"
            >
              <li>
                <h2 className="menu-title">Ações</h2>
                <ul>
                  <li>
                    <a>Visualizar</a>
                  </li>
                  <li>
                    <a>Deletar</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientPlanRows;
