import React from "react";
import { PaginatedResponse } from "utils/interfaces";

const PageController: React.FC<
  PaginatedResponse<any> & { changePage: (index: number) => void }
> = ({ from, to, total, current_page, last_page, changePage }) => {
  return (
    <div className="rounded-md w-full mt-auto flex flex-row items-center justify-between bg-base-200 text-sm py-1 px-2 text-base-content">
      <div className="inline mr-auto">
        Mostrando de {from} a {to} de {total}
      </div>
      {
        <button
          className="p-1"
          disabled={current_page === 1}
          onClick={() => changePage(current_page - 1)}
        >
          Anterior
        </button>
      }

      <button
        className="p-1"
        disabled={current_page === last_page}
        onClick={() => changePage(current_page + 1)}
      >
        Proximo
      </button>
    </div>
  );
};

export default PageController;
