import React, { useEffect, useState } from 'react';
import { ClientPlan, Plan } from 'utils/interfaces';
import ClienteTabs from 'components/Tabs/ClienteTabs';
import { useParams } from 'react-router-dom';
import LabelInsideInput from 'components/Forms/Input/LabelInsideInput';
import Select from 'components/Forms/Input/Select';
import { SubmitHandler, useForm } from 'react-hook-form';
import ClientController from 'Controllers/ClientController';
import ClientPlanTable from 'components/Table/ClientePlanTable';
import PlanController from 'Controllers/PlanController';
import LabelInsideDateInput from 'components/Forms/Input/DateInput';

interface FormData {
    totalValue: string,
    plan_id: string,
    signature_date: string
}

const ClientPlans: React.FC = () => {
    const { id: client_id } = useParams();
    const [tablePlans, setTablePlans] = useState<ClientPlan>(ClientController.defaultClientPlan);
    const [plans, setPlans] = useState<Plan[]>([]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset
    } = useForm<FormData>({})

    const onSubmit: SubmitHandler<FormData> = (data) => {
        if (client_id) {
            ClientController.attachPlan(data.plan_id, client_id, data.signature_date)
            reset()
            updatePlans(client_id)
        }
    }
    const updatePlans = async (id: string) => {
        const plans_list = await ClientController.getPlans(id)
        setTablePlans(plans_list);
        setValue('totalValue', Number(plans_list.total_value).toFixed(2))
        setPlans(await PlanController.listPlans())
    }

    useEffect(() => {
        if (client_id) {
            updatePlans(client_id);
        }
    }, []);

    return (
        <div className="card shadow-xl w-full">
            <div className="card-body">
                <ClienteTabs id={client_id} />
                <form className='flex justify-between' onSubmit={handleSubmit(onSubmit)} >
                    <LabelInsideInput className='bg-neutral flex-1 max-w-44' label="Total Plano:"
                        error={errors.totalValue} placeholder="0.00" disabled {...register("totalValue", { required: false })}
                    />
                    <div className="join join-vertical lg:join-horizontal">
                        <LabelInsideDateInput className='join-item' label="Assinatura" {...register("signature_date", { required: false })} />
                        <Select className='join-item bg-neutral box-border'
                            options={plans.map(({ id, name }) => ({
                                label: name,
                                value: id
                            }))} defaultOption='Selecione um plano'
                            error={errors.plan_id} {...register("plan_id", { required: true })} />
                        <button className="btn join-item">Adicionar</button>
                    </div>
                </form>
                <div className="divider"></div>
                <ClientPlanTable rows={tablePlans.plans} />
            </div>
        </div>
    );
}

export default ClientPlans