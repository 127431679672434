import React, { forwardRef } from "react"
import { FieldError } from "react-hook-form";

interface InputData {
    label?: string
    options: { label: string, value: string | number }[]
    defaultOption: string,
    error?: FieldError
    className?: string
}

const Select = forwardRef<
    HTMLSelectElement,
    InputData
>(({ label, options, defaultOption, error, className, ...rest }, ref) => {
    return <label className={`form-control w-full`}>
        {label && <div className="label">
            <span className="label-text">{label}</span>
        </div>}
        <select defaultValue=""
            className={`select ${className} ${error ? "select-error" : "select-bordered"}`}
            ref={ref} {...rest}>
            <option className="hidden" value="" disabled>{defaultOption}</option>
            {options.map(({ label, value }) => <option key={value} value={value} >{label}</option>)}
        </select>
    </label>
});

export default Select