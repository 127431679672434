import React, { ChangeEvent } from 'react';

interface CheckboxProps {
  title: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  color: string;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  return (
    <div className="form-control">
      <label className="cursor-pointer label">
        <span className="label-text">{props.title}</span>
        <input
          type="checkbox"
          onChange={props.onChange}
          checked={props.checked}
          className={`checkbox checkbox-${props.color} ml-2`}
        />
      </label>
    </div>
  );
};

export default Checkbox;