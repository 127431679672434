import { ConfirmationModal, modalRef } from "components/Modal/confirmDelete";
import FilesController from "Controllers/FilesController";
import React from "react";
import { useParams } from "react-router-dom";
import { ClientFile } from "utils/interfaces";
interface RowData {
  row: ClientFile;
  onClick: (id: string) => void;
}

const ClientFileRows: React.FC<RowData> = ({ row, onClick }) => {
  const { id: client_id } = useParams();

  const confirmDelete = async () => {
    if (client_id) {
      await FilesController.deleteFiles(client_id, row.id);
      onClick(client_id);
    }
  };

  return (
    <React.Fragment key={row.id}>
      <tr className="hover cursor-pointer">
        <th>{row.id}</th>
        <td className="whitespace-nowrap w-full">{row.file_name}</td>
        <td>
          <div className="dropdown dropdown-left dropdown-hover">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost rounded-btn"
            >
              <svg
                className="stroke-base-content"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 3.5H15M0 11.5H15M0 7.5H15" />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content bg-base-100 rounded-box z-[1] mt-4 w-52 p-2 shadow"
            >
              <li>
                <h2 className="menu-title">Ações</h2>
                <ul>
                  <li
                    onClick={async () =>
                      client_id &&
                      (await FilesController.getDownloadLink(
                        client_id,
                        row.id,
                        row.file_name
                      ))
                    }
                  >
                    <a>Baixar</a>
                  </li>
                  <li
                    onClick={() =>
                      (
                        document.getElementById(
                          "my_modal_2"
                        ) as HTMLDialogElement
                      ).showModal()
                    }
                  >
                    <a>Remover</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ConfirmationModal onClick={confirmDelete} />
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ClientFileRows;
