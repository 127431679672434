import { Link } from "react-router-dom";

type MenuItem = {
    label: string;
    icon?: React.ReactNode;
    href: string;
    disabled?: boolean;
};

const MenuButton: React.FC<MenuItem> = ({ label, icon, href }) => {
    return (
        <Link
            to={href}
        >
            <ul className="menu rounded-box px-0 ">
                <li>
                    <summary className="text-base-content stroke-base-content fill-base-content">
                        {icon && icon}
                        {label}
                    </summary>
                </li>
            </ul>
        </Link>
    );
};
export default MenuButton