import Sidebar from "components/Layout/Sidebar";
import React from "react";

type Props = {
  children?: React.ReactNode;
};

const Menu: React.FC<Props> = ({ children }) => {

  return (
    <div className="drawer lg:drawer-open">
      <input id="menu-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        {children}
      </div>
      <div className="drawer-side">
        <label
          htmlFor="menu-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <Sidebar />
      </div>
    </div>
  );
};

export default Menu;
