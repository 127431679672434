import React from "react";
import { Plan } from "utils/interfaces";
interface RowData {
    row: Plan
    onClick: (index?: number) => void,
    active: boolean
}

const PlanRows: React.FC<RowData> = ({ row, active, onClick }) => {
    const activeRow = () => !active ? onClick(row.id) : onClick();

    return <React.Fragment key={row.id}>
        <tr
            className="hover cursor-pointer"
        >
            <th onClick={activeRow}>{row.id}</th>
            <td onClick={activeRow} className="whitespace-nowrap">{row.name}</td>
            <td onClick={activeRow}>{row.value}</td>
            <td>
                <label htmlFor="clientForm" className="">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 10.5L0.146447 10.1464L0 10.2929V10.5H0.5ZM10.5 0.5L10.8536 0.146447C10.6583 -0.0488155 10.3417 -0.0488155 10.1464 0.146447L10.5 0.5ZM14.5 4.5L14.8536 4.85355C15.0488 4.65829 15.0488 4.34171 14.8536 4.14645L14.5 4.5ZM4.5 14.5V15H4.70711L4.85355 14.8536L4.5 14.5ZM0.5 14.5H0C0 14.7761 0.223858 15 0.5 15L0.5 14.5ZM0.853553 10.8536L10.8536 0.853553L10.1464 0.146447L0.146447 10.1464L0.853553 10.8536ZM10.1464 0.853553L14.1464 4.85355L14.8536 4.14645L10.8536 0.146447L10.1464 0.853553ZM14.1464 4.14645L4.14645 14.1464L4.85355 14.8536L14.8536 4.85355L14.1464 4.14645ZM4.5 14H0.5V15H4.5V14ZM1 14.5V10.5H0V14.5H1Z" fill="white" />
                    </svg></label>
            </td>
        </tr>
    </React.Fragment>
}

export default PlanRows;