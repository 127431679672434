class ThemeManager {
    public static getTheme(): string {
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme) {
            return JSON.parse(storedTheme);
        }
        return "ligth";
    }

    public static setTheme(value: string): void {
        localStorage.setItem("theme", JSON.stringify(value));
        if (value === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    public static toggleTheme(): void {
        localStorage.setItem("theme", this.getTheme() === "dark" ? "ligth" : "dark");
        document.documentElement.classList.toggle('dark');
    }

    public static getColor(): string {
        return localStorage.getItem("color") || "cyan";
    }

    public static setColors(color: string): void {
        const root = document.documentElement;

        root.style.setProperty("--color-primary", `var(--color-${color})`);
        root.style.setProperty("--color-primary-50", `var(--color-${color}-50)`);
        root.style.setProperty("--color-primary-100", `var(--color-${color}-100)`);
        root.style.setProperty("--color-primary-light", `var(--color-${color}-light)`);
        root.style.setProperty("--color-primary-lighter", `var(--color-${color}-lighter)`);
        root.style.setProperty("--color-primary-dark", `var(--color-${color}-dark)`);
        root.style.setProperty("--color-primary-darker", `var(--color-${color}-darker)`);

        localStorage.setItem("color", color);
    }

    public static initializeTheme(): void {
        const darkMode = this.getTheme();
        this.setTheme(darkMode);

        const color = this.getColor();
        this.setColors(color);
    }
}

export default ThemeManager;
