import { Navigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { AuthContext } from 'contexts/AuthProvider';
import TextInput from 'components/TextInput/TextInput';
import React, { useContext, useState, ChangeEvent } from 'react';
import NetInoveLogo from './assets/logo_neti9.png';

const Login: React.FC = () => {
  const { login, isLoggedIn } = useContext(AuthContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [remember, setRemember] = useState<boolean>(false);

  const handleSubmit = () => {
    login(email, password, remember);
  };

  if (isLoggedIn === true) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex justify-center items-center h-full">
      <div className="py-9 px-16 bg-gray-800">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h4 className="text-center">Consig CRM</h4>
            </div>
          </div>
          <div className="card-content">
            <div className="card-body">
              <form onSubmit={handleSubmit} className="flex flex-col items-end">
                <TextInput
                  name="email"
                  type="email"
                  topLabelLeft="Email"
                  value={email}
                  onChange={(value: string) => setEmail(value)}
                  format="string"
                />
                <TextInput
                  type="password"
                  format="string"
                  topLabelLeft="Senha"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(value: string) => setPassword(value)}
                />
                <Checkbox
                  title="Manter logado"
                  color="warning"
                  checked={remember}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setRemember(!remember)}
                />
                <Button type="button" color="success" action={handleSubmit} text="ENTRAR" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-consig flex justify-center p-16">
        <img className="img-fluid h-[290px]" src={NetInoveLogo} alt="CRM Netinove" />
      </div>
    </div>
  );
};

export default Login;