const PlanHeader = () => {
    return <thead>
        <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Ações</th>
        </tr>
    </thead>
}

export default PlanHeader