import React, { useState } from "react";
import { Plan } from "utils/interfaces";
import PlanRows from "./Row/PlanRow";
import PlanHeader from "./Header/PlanHeader";

const TableWithExpandableRows: React.FC<{ rows: Plan[] }> = ({ rows }) => {
    const [expandedIndex, setexpandedIndex] = useState<number>();

    const toggleRow = (id?: number) => {
        setexpandedIndex(id)
    };

    return (
        <div className="overflow-x-auto">
            <table className="table w-full">
                <PlanHeader />
                <tbody>
                    {rows.map((row, index) => (
                        <PlanRows row={row} onClick={toggleRow} key={row.id} active={expandedIndex === row.id} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableWithExpandableRows;
