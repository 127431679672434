import React, { forwardRef } from "react"
import { FieldError } from "react-hook-form";

interface InputData {
    label: string;
    error?: FieldError
    className?: string
}

const LabelInsideDateInput = forwardRef<HTMLInputElement, InputData>(({ label, error, className, ...rest }, ref) => {
    return <label className={`input input-bordered flex items-center gap-2 ${className} ${error && "input-error"}`}>
        {label}
        <input type="date" className="grow" placeholder="Daisy" ref={ref} {...rest} />
    </label>
})

export default LabelInsideDateInput