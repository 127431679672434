import React, { useState } from "react";
import { Client } from "utils/interfaces";
import ClientHeader from "./Header/ClentHeader";
import ClientRows from "./Row/ClientRow";
import ClientForm from "components/Forms/ClientForm";

const TableWithExpandableRows: React.FC<{ rows: Client[] }> = ({ rows }) => {
    const [expandedIndex, setexpandedIndex] = useState<number>();
    const [selectedClient, setSelectedClient] = useState<number>();

    const toggleRow = (id?: number) => {
        setexpandedIndex(id)
    };

    const editClient = (id: number) => {
        return () => setSelectedClient(id)
    }
    return (
        <div className="overflow-x-auto">
            <table className="table w-full">
                <ClientHeader />
                <tbody>
                    {rows.map((row, index) => (
                        <ClientRows row={row} onClick={toggleRow} key={row.id} active={expandedIndex === row.id} editRow={editClient(row.id)} />
                    ))}
                </tbody>
            </table>
            <ClientForm idForm="clientForm" selectedID={selectedClient} />
        </div>
    );
};

export default TableWithExpandableRows;
