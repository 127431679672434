import { toast } from "react-toastify";
import apiClient from "services/axios/axios";
import { PaginatedResponse, Plan } from "utils/interfaces";

export default class PlanController {
    static defaultPaginatedResponse: PaginatedResponse<Plan> = {
        current_page: 1,
        data: [],
        from: 0,
        last_page: 1,
        per_page: 0,
        to: 0,
        total: 0,
    }

    static async getPlanPage(id: number): Promise<PaginatedResponse<Plan>> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/plans/index?page=${id}`), {
            pending: 'Atualizando lista de Clientes...',
            error: 'Erro ao obter lista de Clientes',
        })
            .then((response) => {
                if (response.data) {
                    return response.data as PaginatedResponse<Plan>
                }
                else {
                    return this.defaultPaginatedResponse
                }
            })
            .catch((error) => {
                console.log(error);
                return this.defaultPaginatedResponse
            });
    };

    static async listPlans(): Promise<Plan[]> {
        await apiClient.get('/sanctum/csrf-cookie');
        return toast.promise(apiClient.get(`/plans/list`
        ), {
            pending: 'Obtendo Planos',
            error: 'Erro ao realizar listagem de planos',
        })
            .then((response) => {
                if (response.data) {
                    return response.data as Plan[]
                }
                else {
                    return []
                }
            })
            .catch((error) => {
                console.log(error);
                return []
            });
    };
}