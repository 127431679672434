const ClientHeader = () => {
    return <thead>
        <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Fantasia</th>
            <th>CNPJ</th>
            <th>Endereço</th>
        </tr>
    </thead>
}

export default ClientHeader