const ClientPlanHeader = () => {
    return <thead>
        <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Status</th>
            <th>Assinatura</th>
            <th>Valor R$</th>
            <th>Acões</th>
        </tr>
    </thead>
}

export default ClientPlanHeader