import React, { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import ThemeManager from "utils/ThemeManager";

const Navbar: React.FC = () => {
    const [isMobileMainMenuOpen, setMobileMainMenuOpen] = useState(false);
    const [isMobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);
    const [theme, setTheme] = useLocalStorage('theme', 'light')

    const toggleTheme = () => { theme === 'dark' ? setTheme("light") : setTheme("dark") };

    useEffect(() => {
        ThemeManager.setTheme(theme)
    }, [theme])

    return (
        <header className="relative font-roboto bg-base-200 h-12">
            <div className="flex items-center justify-between p-2">
                {/* Mobile menu button */}
                <label
                    htmlFor="menu-drawer"
                    className="btn btn-primary drawer-button lg:hidden"
                >
                    <button
                        onClick={() => setMobileMainMenuOpen(!isMobileMainMenuOpen)}
                        className="p-1 transition-colors duration-200 rounded-md text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark md:hidden focus:outline-none focus:ring"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </label>

                <form action="#" method="POST">
                    <div className="flex items-center ">
                        <input
                            type="text"
                            name="search"
                            placeholder="Pesquisar"
                            className="border outline-none border-darkpallete-davysgrey lg:w-96 w-full pl-10 pr-4 py-1 rounded-md focus:outline-none focus:ring focus:ring-darkpallete-bluecola dark:bg-darkpallete-charcoal dark:focus:ring-white"
                        />
                        <div className="absolute pl-3">
                            <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 14.5L10.5 10.5M6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5Z" stroke="white" />
                            </svg>
                        </div>
                    </div>
                </form>
                {/* Mobile sub menu button */}
                <button
                    onClick={() => setMobileSubMenuOpen(!isMobileSubMenuOpen)}
                    className="p-1 transition-colors duration-200 rounded-md text-primary-lighter bg-primary-50 hover:text-primary hover:bg-primary-100 dark:hover:text-light dark:hover:bg-primary-dark dark:bg-dark md:hidden focus:outline-none focus:ring"
                >
                    <span className="sr-only">Open sub menu</span>
                    <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                    </svg>
                </button>

                {/* Desktop right buttons */}
                <nav aria-label="Secondary" className="hidden space-x-2 md:flex md:items-center">
                    <label className="grid cursor-pointer place-items-center">
                        <input
                            type="checkbox"
                            value="dark"
                            className="toggle theme-controller bg-base-content col-span-2 col-start-1 row-start-1" />
                        <svg
                            className="stroke-base-100 fill-base-100 col-start-1 row-start-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <circle cx="12" cy="12" r="5" />
                            <path
                                d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
                        </svg>
                        <svg
                            className="stroke-base-100 fill-base-100 col-start-2 row-start-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                        </svg>
                    </label>
                    {/* Toggle dark theme button */}
                    <button onClick={toggleTheme} className="relative focus:outline-none">
                        <div className={`w-12 h-6 transition rounded-full ${theme === 'dark' ? "bg-primary-lighter" : "bg-primary-100"}`} />
                        <div
                            className={`absolute top-0 left-0 inline-flex items-center justify-center w-6 h-6 transition-all duration-150 transform scale-110 rounded-full shadow-sm ${theme === 'dark' ? "translate-x-6 bg-primary-darker" : "translate-x-0 bg-white"
                                }`}
                        >
                            {theme === 'dark' ? (
                                <svg className="w-4 h-4 text-primary-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                                    />
                                </svg>
                            ) : (
                                <svg className="w-4 h-4 text-primary-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                                </svg>
                            )}
                        </div>
                    </button>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
