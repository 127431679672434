import apiClient from 'services/axios/axios';
import React, { createContext, useState, useEffect } from 'react';

interface AuthContextType {
  isLoggedIn: boolean;
  userId: number | null;
  userName: string | null;
  errorMessage: string;
  login: (email: string, password: string, remember: boolean) => void;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | null>(null);

  const login = async (email: string, password: string, remember: boolean) => {
    await apiClient.get('/sanctum/csrf-cookie');
    await apiClient.post('/login', {
      email: email,
      password: password,
      remember: remember,
      spa: true,
    }).then(
      (response) => {
        setUserId(response.data.user.id);
        setUserName(response.data.user.name);
        setErrorMessage('');
        setIsLoggedIn(true);
      },
      (error) => {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Could not complete the login');
        }
      }
    );
  };

  const getUser = async () => {
    await apiClient.get('/sanctum/csrf-cookie');
    await apiClient.get('/user').then(
      (response) => {
        setUserId(response.data.id);
        setUserName(response.data.name);
        setIsLoggedIn(true);
      },
      (error) => {
        setIsLoggedIn(false);
      }
    );
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, userId, userName, errorMessage, login }}>
      {children}
    </AuthContext.Provider>
  );
};
