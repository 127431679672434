import React, { useEffect, useState } from "react";
import TableWithExpandableRows from "components/Table/ClientTable";
import { Client, PaginatedResponse } from "utils/interfaces";
import ClientController from "Controllers/ClientController";
import PageController from "components/Table/PageController";

const ClientList: React.FC = () => {
  const [tableClient, setTableClient] = useState<PaginatedResponse<Client>>(
    ClientController.defaultPaginatedResponse
  );

  const getClientsPerPage = async (id: number) => {
    setTableClient(await ClientController.getClientPage(id));
  };

  useEffect(() => {
    getClientsPerPage(1);
  }, []);

  return (
    <div className="card shadow-xl w-full">
      <div className="card-body text-base-content">
        <h2 className="card-title mb-1">
          <div className="mr-auto">Clientes</div>
          <label htmlFor="clientForm" className="btn btn-primary">
            Adicionar Cliente
          </label>
        </h2>
        <div className="overflow-x-auto">
          <TableWithExpandableRows rows={tableClient.data} />

          <PageController {...tableClient} changePage={getClientsPerPage} />
        </div>
      </div>
    </div>
  );
};

export default ClientList;
