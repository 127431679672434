import React, { useState } from "react";
import { ClientPlanInfo, Plan } from "utils/interfaces";
import ClientPlanHeader from "./Header/ClientPlanHeader";
import ClientPlanRows from "./Row/ClientPlanRow";

const ClientPlanTable: React.FC<{ rows: ClientPlanInfo[] }> = ({ rows }) => {
    return (
        <table className="table w-full">
            <ClientPlanHeader />
            <tbody>
                {rows.map((row, index) => (
                    <ClientPlanRows row={row} />
                ))}
            </tbody>
        </table>
    );
};

export default ClientPlanTable;
